<template>
  <div>
    <van-nav-bar title="我的" fixed />

    <van-cell-group class="user-group"> <van-cell title="登录信息" icon="records" is-link to="/Login"/></van-cell-group>
    <van-cell-group>
      <van-cell title="归属公司" :value="userInfo.company" />
      <van-cell title="归属部门" :value="userInfo.deptName" />
      <van-cell title="登录名" :value="userInfo.userName" />
      <van-cell title="姓名" :value="userInfo.cname" />
      <van-cell title="性别" :value="userInfo.gender" />
      <van-cell title="出生日期" :value="userInfo.birthday" />
      <van-cell title="移动电话" :value="userInfo.mobile" />
      <van-cell title="邮箱" :value="userInfo.email" />
      <van-cell title="用户类型" :value="userInfo.userType | toSysDict('userType')" />
      <van-cell title="用户编码" :value="userInfo.userCode" />
      <van-cell title="主管" :value="userInfo.directManagerName" />
      <van-cell title="岗位" :value="userInfo.postName" />
    </van-cell-group>

    <div style="text-align: center;margin-bottom: 80px;margin-top: 10px">
      <van-button size="small" type="danger" style="width: 60%" @click="logout">退出登录</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'User',
  data() {
    return {
      userInfo: {}
    };
  },
  methods: {
    getData() {
      this.$myHttp({
        method: 'post',
        headers: {
          isLoading: false
        },
        url: '/microarch/sys/sysuser/findUserInfo',
        data: {
          entity: {
            loginName: sessionStorage.userName,
            lang: 'zh_CH'
          }
        }
      }).then(res => {
        this.userInfo = res.data.entity;
        console.log('res', res.data.entity);
      });
    },
    logout() {
      this.$myHttp({
        method: 'post',
        url: '/microarch/auth/logout',
        data: {
          entity: {
            loginName: sessionStorage.getItem('userName')
          }
        }
      }).then(res => {
        this.$router.push({
          name: 'Login'
        });
        sessionStorage.clear();
        localStorage.clear();
      });
    }
  },
  mounted() {
    this.getData();
  }
};
</script>

<style lang="less" scoped></style>
